// Roboto
@font-face {
  font-family: "Roboto";
  font-weight: 100;
  src: local("Roboto"), url("../fonts/Roboto-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 300;
  src: local("Roboto"), url("../fonts/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  src: local("Roboto"), url("../fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  src: local("Roboto"), url("../fonts/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  src: local("Roboto"), url("../fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 900;
  src: local("Roboto"), url("../fonts/Roboto-Black.ttf") format("truetype");
}

// SpaceGrotesk
@font-face {
  font-family: "SpaceGrotesk";
  font-weight: 300;
  src: local("SpaceGrotesk"), url("../fonts/SpaceGrotesk-Light.ttf") format("truetype");
}

@font-face {
  font-family: "SpaceGrotesk";
  font-weight: 400;
  src: local("SpaceGrotesk"), url("../fonts/SpaceGrotesk-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "SpaceGrotesk";
  font-weight: 500;
  src: local("SpaceGrotesk"), url("../fonts/SpaceGrotesk-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "SpaceGrotesk";
  font-weight: 600;
  src: local("SpaceGrotesk"), url("../fonts/SpaceGrotesk-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "SpaceGrotesk";
  font-weight: 700;
  src: local("SpaceGrotesk"), url("../fonts/SpaceGrotesk-Bold.ttf") format("truetype");
}

// DM Sans
@font-face {
  font-family: "DMSans";
  font-weight: 400;
  src: local("DMSans"), url("../fonts/DMSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans";
  font-weight: 500;
  src: local("DMSans"), url("../fonts/DMSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans";
  font-weight: 700;
  src: local("DMSans"), url("../fonts/DMSans-Bold.ttf") format("truetype");
}
