// IMPORTS
// =====================
@import "./fonts.scss";
@import "react-alice-carousel/lib/scss/alice-carousel.scss";

// COLORS
// =====================
$background-cream: #FDF9EE;
$charcoal-dark: #333333;
$charcoal-medium: #575757;
$charcoal-light: #7B7B7B;
$charcoal-ultra-light: #C2C2C2;
$emphasis-red: #B6000F;

// GLOBALS
// =====================
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

a, input, textarea {
  all: unset;
  display: block;
}

h1, h2, h3, h4, h5, h6, p, hr, pre, a, input, span {
  margin: 0;
  font-family: "SpaceGrotesk";
}

a, button {
  cursor: pointer;
}

.Container {
  min-height: 100vh;
  background-color: $background-cream;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.App {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  max-width: 1280px;
  background-color: $background-cream;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media screen and (min-width: 0px) and (max-width: 480px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media screen and (min-width: 480px) {
    padding-left: 64px;
    padding-right: 64px;
  }
}

.flex-spacer {
  flex: 1;
}

// COMPONENTS
// =====================
.Header {
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  .branding {
    img.logo {
      width: 160px;
    }
  }

  @media screen and (min-width: 0px) and (max-width: 480px) {
    .navigation-links {
      display: none;
    }
    .hamburger-icon, .close-icon {
      height: 24px;
      width: 24px;
    }
    .hamburger-menu {
      background-color: $background-cream;
      width: 100%;
      min-height: calc(100vh - 90px);
      position: absolute;
      top: 90px;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .navigation-link {
        padding-top: 40px;
        padding-bottom: 40px;
        font-weight: 400;
        font-size: 16px;
        color: $charcoal-medium;
        &.selected {
          font-weight: 500;
          color: $charcoal-dark;
        }
        &:not(:first-child) {
          border-top: 1px solid $charcoal-ultra-light;
        }
      }
    }
  }

  @media screen and (min-width: 480px) {
    .hamburger-container {
      display: none;
    }

    .navigation-links {
      display: flex;
      flex-direction: row;
      .navigation-link {
        font-weight: 400;
        font-size: 16px;
        color: $charcoal-medium;
        &:not(:first-child) {
          margin-left: 22px;
        }
        &.selected {
          font-weight: 500;
          color: $charcoal-dark;
        }
      }
    }
  }

}

.Footer {
  width: 100%;

  @media screen and (min-width: 0px) and (max-width: 480px) {
    height: 458px;

    .section {
      &.section-primary {
        height: 370px;
        padding-top: 36px;
        padding-bottom: 40px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .content {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }

        .content-section-wrapper {
          margin-top: 30px;
        }
      }
    }
  }

  @media screen and (min-width: 480px) {
    height: 260px;

    .section {
      &.section-primary {
        height: 200px;
        padding-top: 40px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        .content {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
        }

        .content-section-wrapper {
          &:not(:first-child) {
            margin-left: 40px;
          }
        }
      }
    }
  }

  .section {
    border-top: 1px solid $charcoal-ultra-light;

    &.section-primary {

      .branding {
        img.logo {
          width: 160px;
        }
      }

      .content {

        .content-section-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
        }

        .content-section {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          &:not(:first-child) {
            margin-left: 40px;
          }

          .content-title {
            font-weight: 500;
            font-size: 16px;
            color: $charcoal-dark;
          }

          .navigation-link {
            margin-top: 16px;
            font-weight: 400;
            font-size: 16px;
            color: $charcoal-medium;
          }

        }

      }

      .social-links {
        margin-top: 16px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        img {
          width: 24px;
        }
        .social-link:not(:first-child) {
          margin-left: 28px;
        }
      }

    }

    &.section-secondary {

      @media screen and (min-width: 0px) and (max-width: 480px) {
        height: 88px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p.copyright { margin-top: 21px; }
        .navigation-links { margin-bottom: 21px; }
      }

      @media screen and (min-width: 480px) {
        height: 60px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }

      p.copyright {
        font-weight: 400;
        font-size: 12px;
        color: $charcoal-light;
      }

      .navigation-links {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .vertical-bar {
          font-weight: 400;
          font-size: 12px;
          color: $charcoal-light;
          margin-left: 8px;
          margin-right: 8px;
        }

        .navigation-link {
          font-weight: 400;
          font-size: 12px;
          color: $charcoal-light;
        }
      }

    }
  }
}

.button {
  height: 48px;
  background-color: $charcoal-dark;
  &.inverted {
    background-color: $background-cream;
    border: 1px solid $charcoal-dark;
    .button-text { color: $charcoal-dark; }
  }
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .button-text {
    font-size: 16px;
    font-weight: 500;
    color: $background-cream;

    span.emphasis {
      font-weight: 700;
    }

  }

  .external-link {
    margin-left: 4px;
    width: 16px;
  }

}

input {
  width: 100%;
  height: 54px;
  border-bottom: 1px solid $charcoal-ultra-light;
}

.checkbox-wrapper {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .label {
    line-height: 140%;
    font-size: 14px;
    font-weight: 400;
    color: $charcoal-dark;
    margin-left: 8px;
  }

  .checkbox {
    width: 16px;
    min-width: 16px;
    height: 16px;
    min-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    border: 2px solid $charcoal-dark;

    &.checked {
      background-color: $charcoal-dark;
    }
  }

}

.paper-carousel {
  overflow-x: hidden;

  @media screen and (min-width: 0px) and (max-width: 480px) {
    .paper {
      width: 348px;
      height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding-left: 30px;
      padding-right: 30px;
      border: 1px solid $charcoal-ultra-light;

      img.paper-thumbnail {
        margin-top: 32px;
        width: 100px;
      }

      .paper-title {
        margin-top: 24px;
        font-size: 24px;
        font-weight: 400;
        line-height: 125%;
        letter-spacing: -0.01em;
        color: $charcoal-dark;
        text-align: center;
      }

      .paper-authors {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: -0.01em;
        color: $charcoal-dark;
        text-align: center;
      }
    }

    .alice-carousel {

      .alice-carousel__stage-item {
        &.__active {
          margin-right: 16px;
        }
      }

      .alice-carousel__prev-btn {
        margin: 0px;
        padding: 0px;
        position: absolute;
        top: 232px;
        left: -12px;
      }

      .alice-carousel__next-btn {
        margin: 0px;
        padding: 0px;
        position: absolute;
        top: 232px;
        left: calc(100% - 24px);
      }
    }

    .carousel-button {
      cursor: pointer;
      width: 36px;
      height: 36px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 6px;
      padding-right: 4px;
      border: 1px solid $charcoal-ultra-light;

      .carousel-button-image {
        width: 16px;
      }
    }

  }

  @media screen and (min-width: 480px) {
    .paper {
      width: 352px;
      height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding-left: 30px;
      padding-right: 30px;
      border: 1px solid $charcoal-ultra-light;

      img.paper-thumbnail {
        margin-top: 32px;
        width: 100px;
      }

      .paper-title {
        margin-top: 24px;
        font-size: 24px;
        font-weight: 400;
        line-height: 125%;
        letter-spacing: -0.01em;
        color: $charcoal-dark;
        text-align: center;
      }

      .paper-authors {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: -0.01em;
        color: $charcoal-dark;
        text-align: center;
      }
    }

    .alice-carousel {

      .alice-carousel__stage-item {
        &.__active {
          margin-right: 16px;
        }
      }

      .alice-carousel__prev-btn {
        margin: 0px;
        padding: 0px;
        position: absolute;
        top: 232px;
        left: -18px;
      }

      .alice-carousel__next-btn {
        margin: 0px;
        padding: 0px;
        position: absolute;
        top: 232px;
        left: calc(100% - 18px);
      }
    }

    .carousel-button {
      cursor: pointer;
      width: 36px;
      height: 36px;
      padding: 10px;
      border: 1px solid $charcoal-ultra-light;

      .carousel-button-image {
        width: 16px;
      }
    }

  }

}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.50) !important;
}

.bio-modal {
  position: absolute;
  left: calc(50% - 216px);
  top: 40px;
  outline: 0;
  background-color: white;
  width: 432px;
  border-radius: 10px;
  padding: 40px;
  .close-modal {
    height: 24px;
    width: 24px;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
  .team-member {
    display: flex;
    flex-direction: column;
    height: 500px;
    overflow-y: auto;
    
    .team-member-photo {
      height: 250px;
      width: 250px;
      border-radius: 50%;
      align-self: center;
    }
    .name {
      margin-top: 24px;
      color: $charcoal-dark;
      font-size: 28px;
    }
    .title {
      color: $charcoal-dark;
      font-family: "DMSans";
      font-weight: 400;
      font-size: 16px;
    }
    .bio {
      color: $charcoal-medium;
      font-family: "DMSans";
      font-weight: 400;
      font-size: 14px;
      &:nth-child(4) {
        margin-top: 8px;
      }
      &:nth-child(n+5) {
        margin-top: 16px;
      }
    }
  }
}

// SCREENS
// =====================
.screen.home-screen {

  @media screen and (min-width: 0px) and (max-width: 480px) {
    .text-content {
      .section-title {
        font-size: 18px;
        font-weight: 400;
        color: $charcoal-dark;
      }
      .section-description {
        margin-top: 15px;
        font-size: 24px;
        font-weight: 400;
        color: $charcoal-dark;
        line-height: 125%;
        letter-spacing: -0.01em;

        span.emphasis {
          color: $emphasis-red;
        }
      }
    }

    .section.powered-by-section {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      img.section-image {
        margin-top: 32px;
        width: 343px;
        align-self: center;
      }

      .text-content {
        .attribution {
          font-size: 18px;
          font-weight: 400;
          color: $charcoal-medium;

          span.emphasis {
            font-family: "Roboto";
            font-size: 20px;
            font-weight: 700;
            color: $charcoal-dark;
          }
        }

        .tagline {
          margin-top: 15px;
          font-size: 32px;
          line-height: 120%;
          letter-spacing: -0.01em;
          font-weight: 400;
          color: $charcoal-dark;

          span.emphasis {
            color: $emphasis-red;
          }
        }

        .stats {
          margin-top: 24px;
          width: 282px;

          .stats-section {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            height: 54px;

            &:not(:last-child) {
              border-bottom: 1px solid $charcoal-ultra-light;
            }

            p.primary {
              font-size: 32px;
              font-weight: 400;
              color: $charcoal-dark;
              margin-right: 16px;
              letter-spacing: -0.01em;
            }

            p.secondary {
              font-size: 24px;
              font-weight: 400;
              color: $charcoal-medium;
              letter-spacing: -0.01em;
            }
          }
        }
      }
    }

    .section.mission-section {
      margin-top: 64px;
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: center;

      img.section-image {
        margin-top: 40px;
        width: 343px;
        align-self: center;
      }

    }

    .read-more {
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .read-more-text {
        font-size: 16px;
        font-weight: 400;
        color: $charcoal-dark;
        text-decoration-line: underline;
      }

      img.read-more-arrow {
        margin-top: 3px;
        margin-left: 4px;
        width: 12px;
      }

      img.external-link {
        margin-left: 4px;
        height: 16px;
      }
    }

    .section.why-section {
      margin-top: 64px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      img.section-image {
        align-self: center;
        margin-top: 40px;
        width: 343px;
      }
    }

    .section.how-section {
      margin-top: 64px;
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-start;

      img.section-image {
        align-self: center;
        margin-top: 40px;
        width: 343px;
      }
    }

    .section.learn-more-section {
      margin-top: 64px;
      display: flex;
      justify-content: center;
      align-items: center;

      .content-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .read-more {
          margin-top: 24px;
        }
      }
    }

    .section.winning-papers-section {
      margin-top: 64px;

      .text-content .section-description {
        max-width: 734px;
      }

      .winning-paper-highlight {
        &:nth-child(2) {
          margin-top: 40px;
        }

        &:not(:nth-child(2)) {
          margin-top: 60px;
        }

        .highlight-header {
          height: 36px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          .highlight-title {
            font-size: 28px;
            font-weight: 400;
            line-height: 125%;
            color: $charcoal-dark;
            letter-spacing: -0.01em;
          }
        }
      }
    }

    .section.faq-section {
      margin-top: 64px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .section-title {
        font-size: 24px;
        font-weight: 400;
        line-height: 120%;
        color: $charcoal-dark;
        letter-spacing: -0.01em;
      }

      .faq-items {
        margin-top: 24px;

        .faq-item {
          position: relative;
          cursor: pointer;
          padding-top: 24px;
          padding-bottom: 24px;
          border-top: 1px solid $charcoal-ultra-light;
          &:last-child {
            border-bottom: 1px solid $charcoal-ultra-light;
          }

          .faq-question {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .faq-question-text {
              font-size: 16px;
              font-weight: 400;
              line-height: 140%;
              letter-spacing: -0.01em;
              color: $charcoal-dark;
            }

            .faq-collapse-icon {
              width: 14px;
            }

          }

          .faq-answer {
            margin-top: 16px;
            font-size: 14px;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: -0.01em;
            color: $charcoal-dark;
          }

        }

      }

    }

    .section.mailing-list-section {
      margin-top: 64px;
      margin-bottom: 80px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .section-title {
        font-size: 32px;
        font-weight: 400;
        line-height: 120%;
        color: $charcoal-dark;
        letter-spacing: -0.01em;
      }

      .section-component.email-input {
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }

      .email-input-container {
        width: 360px;
        margin-right: 24px;
      }
    }

  }

  @media screen and (min-width: 480px) {
    .text-content {
      .section-title {
        font-size: 18px;
        font-weight: 400;
        color: $charcoal-dark;
      }
      .section-description {
        margin-top: 15px;
        max-width: 555px;
        font-size: 32px;
        font-weight: 400;
        color: $charcoal-dark;
        line-height: 120%;
        letter-spacing: -0.01em;

        span.emphasis {
          color: $emphasis-red;
        }
      }
    }

    .section.powered-by-section {
      margin-top: 60px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      img.section-image {
        width: 457px;
      }

      .text-content {
        .attribution {
          font-size: 18px;
          font-weight: 400;
          color: $charcoal-medium;

          span.emphasis {
            font-family: "Roboto";
            font-size: 20px;
            font-weight: 700;
            color: $charcoal-dark;
          }
        }

        .tagline {
          margin-top: 15px;
          max-width: 555px;
          font-size: 56px;
          line-height: 100%;
          letter-spacing: 0.01em;
          font-weight: 400;
          color: $charcoal-dark;

          span.emphasis {
            color: $emphasis-red;
          }
        }

        .stats {
          margin-top: 40px;
          width: 282px;

          .stats-section {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            height: 54px;

            &:not(:last-child) {
              border-bottom: 1px solid $charcoal-ultra-light;
            }

            p.primary {
              font-size: 32px;
              font-weight: 400;
              color: $charcoal-dark;
              margin-right: 16px;
              letter-spacing: -0.01em;
            }

            p.secondary {
              font-size: 24px;
              font-weight: 400;
              color: $charcoal-medium;
              letter-spacing: -0.01em;
            }
          }
        }
      }
    }

    .section.mission-section {
      margin-top: 60px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      img.section-image {
        width: 467px;
      }

    }

    .read-more {
      margin-top: 24px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .read-more-text {
        font-size: 16px;
        font-weight: 400;
        color: $charcoal-dark;
        text-decoration-line: underline;
      }

      img.read-more-arrow {
        margin-left: 4px;
        width: 12px;
      }

      img.external-link {
        margin-left: 4px;
        height: 16px;
      }
    }

    .section.why-section {
      margin-top: 60px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      img.section-image {
        width: 552px;
      }
    }

    .section.how-section {
      margin-top: 60px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      img.section-image {
        width: 552px;
      }
    }

    .section.learn-more-section {
      margin-top: 60px;
      display: flex;
      justify-content: center;
      align-items: center;

      .content-container {
        width: 475px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .read-more {
          margin-top: 0px;
        }
      }
    }

    .section.winning-papers-section {
      margin-top: 60px;

      .text-content .section-description {
        max-width: 734px;
      }

      .winning-paper-highlight {
        &:nth-child(2) {
          margin-top: 40px;
        }

        &:not(:nth-child(2)) {
          margin-top: 60px;
        }

        .highlight-header {
          height: 36px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          .highlight-title {
            font-size: 28px;
            font-weight: 400;
            line-height: 125%;
            color: $charcoal-dark;
            letter-spacing: -0.01em;
          }
        }
      }
    }

    .section.faq-section {
      margin-top: 60px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .section-title {
        font-size: 32px;
        font-weight: 400;
        line-height: 120%;
        color: $charcoal-dark;
        letter-spacing: -0.01em;
      }

      .faq-items {
        margin-top: 40px;

        .faq-item {
          position: relative;
          cursor: pointer;
          padding-top: 24px;
          padding-bottom: 24px;
          border-top: 1px solid $charcoal-ultra-light;
          &:last-child {
            border-bottom: 1px solid $charcoal-ultra-light;
          }

          .faq-question {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .faq-question-text {
              font-size: 24px;
              font-weight: 400;
              line-height: 125%;
              letter-spacing: -0.01em;
              color: $charcoal-dark;
            }

            .faq-collapse-icon {
              width: 14px;
            }

          }

          .faq-answer {
            margin-top: 16px;
            font-size: 16px;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: -0.01em;
            color: $charcoal-dark;
          }

        }

      }

    }

    .section.mailing-list-section {
      margin-top: 100px;
      margin-bottom: 100px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .section-title {
        font-size: 32px;
        font-weight: 400;
        line-height: 120%;
        color: $charcoal-dark;
        letter-spacing: -0.01em;
      }

      .section-component.email-input {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }

      .email-input-container {
        width: 437px;
        margin-right: 24px;
      }
    }

  }

  .see-all {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .see-all-text {
      font-size: 16px;
      font-weight: 400;
      color: $charcoal-dark;
      text-decoration-line: underline;
    }

    img.see-all-arrow {
      margin-left: 4px;
      width: 12px;
    }

  }

}

.screen.team-screen {
  @media screen and (min-width: 0px) and (max-width: 480px) {
    .banner-section {
      display: flex;
      flex-direction: column;
      .text-content {
        margin-top: 12px;
        .section-title {
          color: $charcoal-dark;
          font-size: 56px;
        }
        .section-description {
          font-family: "DMSans";
          font-size: 24px;
          font-weight: 400;
          span.emphasis {
            color: $charcoal-dark;
            color: $emphasis-red;
          }
        }
      }
      img {
        margin-top: 8px;
        margin-bottom: 16px;
      }
    }

    .team-section {
      .team-members {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;

        .team-member {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 24px;

          img.team-member-photo {
            align-self: center;
            height: 250px;
            width: 250px;
            border-radius: 50%;
          }

          .name {
            margin-top: 24px;
            color: $charcoal-dark;
            font-size: 28px;
          }

          .title {
            color: $charcoal-dark;
            font-size: 16px;
          }

          .actions {
            margin-top: 24px;
            .button {
              height: 36px;
              width: 96px;
              font-size: 16px;
              padding-left: 10px;
              padding-right: 10px;
            }
          }

        }
      }
    }

    .team-section.advisory-board {
      .advisory-board-title {
        margin-top: 120px;
        color: $charcoal-dark;
        font-size: 56px;
        font-weight: 400;
      }
      margin-bottom: 100px;
    }
  }

  @media screen and (min-width: 480px) {
    .banner-section {
      height: 635px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .text-content {
        width: 50%;
        .section-title {
          color: $charcoal-dark;
          font-size: 90px;
        }
        .section-description {
          font-family: "DMSans";
          font-size: 32px;
          font-weight: 400;
          span.emphasis {
            color: $charcoal-dark;
            color: $emphasis-red;
          }
        }
      }
      img {
        width: 50%;
      }
    }

    .team-section {
      .team-members {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;

        .team-member {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: 350px;
          &:nth-child(n+4) {
            margin-top: 48px;
          }

          img.team-member-photo {
            height: 250px;
            width: 250px;
            border-radius: 50%;
          }

          .name {
            margin-top: 24px;
            color: $charcoal-dark;
            font-size: 28px;
          }

          .title {
            color: $charcoal-dark;
            font-size: 16px;
          }

          .actions {
            margin-top: 24px;
            .button {
              height: 36px;
              width: 96px;
              font-size: 16px;
              padding-left: 10px;
              padding-right: 10px;
            }
          }

        }
      }
    }

    .team-section.advisory-board {
      .advisory-board-title {
        margin-top: 120px;
        color: $charcoal-dark;
        font-size: 90px;
        font-weight: 400;
      }
      margin-bottom: 100px;
    }
  }
}

.screen.about-screen {

  @media screen and (min-width: 0px) and (max-width: 480px) {
    .section-title {
      font-size: 18px;
      font-weight: 400;
      color: $charcoal-dark;
    }

    .section-description {
      margin-top: 16px;
      font-size: 24px;
      font-weight: 400;
      color: $charcoal-dark;
      line-height: 125%;
      letter-spacing: -0.01em;
      span.emphasis {
        color: $emphasis-red;
      }
    }

    .section.banner-section {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .text-content {
        .primary {
          font-size: 32px;
          line-height: 120%;
          letter-spacing: -0.01em;
          font-weight: 400;
          color: $charcoal-dark;
          span.emphasis {
            color: $emphasis-red;
          }
        }
        .secondary {
          margin-top: 15px;
          font-size: 20px;
          line-height: 125%;
          letter-spacing: -0.01em;
          font-weight: 400;
          color: $charcoal-dark;
        }
      }

      img.section-image {
        margin-top: 24px;
        width: 343px;
      }

    }

    .section.selection-process-section {
      margin-top: 64px;

      .selection-process-steps {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }

      .selection-process-step {
        position: relative;
        border-top: 1px solid $charcoal-ultra-light;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .step-number {
          position: absolute;
          top: 8px;
          font-size: 32px;
          font-weight: 400;
          color: $charcoal-dark;
        }

        .step-title {
          margin-top: 16px;
          margin-left: 32px;
          font-size: 20px;
          font-weight: 400;
          color: $charcoal-dark;
          line-height: 125%;
        }

        .step-details {
          margin-left: 32px;
          margin-top: 8px;
          font-size: 16px;
          font-weight: 400;
          color: $charcoal-dark;
          line-height: 140%;
          margin-bottom: 16px;
        }
      }
    }

    .section.awarding-the-prize-section {
      margin-top: 64px;

      .awarding-infos {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .awarding-info {
          border-top: 1px solid $charcoal-ultra-light;

          .info-title {
            margin-top: 16px;
            font-size: 20px;
            font-weight: 400;
            color: $charcoal-dark;
            line-height: 125%;
          }

          .info-details {
            margin-top: 8px;
            font-size: 16px;
            font-weight: 400;
            color: $charcoal-dark;
            line-height: 140%;
            margin-bottom: 16px;
          }
        }

      }
    }

    .section.experts-section {
      margin-top: 64px;
      margin-bottom: 64px;

      .experts {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .experts-column {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
        }

        .expert {
          border-top: 1px solid $charcoal-ultra-light;
          margin-top: 24px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          .expert-title {
            margin-top: 18px;
            font-size: 16px;
            font-weight: 600;
            color: $charcoal-dark;
            line-height: 135%;
            letter-spacing: -0.01em;
          }

          .expert-bio {
            margin-top: 8px;
            font-size: 16px;
            font-weight: 400;
            color: $charcoal-dark;
            line-height: 135%;
            letter-spacing: -0.01em;
          }

          .expert-linkedin {
            margin-top: 8px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .linkedin-text {
              border-bottom: 1px solid $charcoal-dark;
              font-size: 16px;
              font-weight: 400;
              color: $charcoal-dark;
            }

            img.view-more-arrow {
              margin-left: 4px;
              width: 12px;
            }
          }
        }
      }

      .more-experts-disclaimer {
        margin-top: 64px;
        font-size: 16px;
        font-weight: 400;
        color: $charcoal-light;
        text-align: center;
      }

    }

  }

  @media screen and (min-width: 480px) {
    .section-title {
      font-size: 18px;
      font-weight: 400;
      color: $charcoal-dark;
    }

    .section-description {
      margin-top: 24px;
      max-width: 750px;
      font-size: 32px;
      font-weight: 400;
      color: $charcoal-dark;
      line-height: 120%;
      letter-spacing: -0.01em;
      span.emphasis {
        color: $emphasis-red;
      }
    }

    .section.banner-section {
      margin-top: 60px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      img.section-image {
        width: 457px;
      }

      .text-content {
        .primary {
          margin-top: 15px;
          max-width: 555px;
          font-size: 56px;
          line-height: 100%;
          letter-spacing: -0.01em;
          font-weight: 400;
          color: $charcoal-dark;
          span.emphasis {
            color: $emphasis-red;
          }
        }
        .secondary {
          margin-top: 15px;
          font-size: 28px;
          line-height: 125%;
          letter-spacing: -0.01em;
          font-weight: 400;
          color: $charcoal-dark;
        }
      }

    }

    .section.selection-process-section {
      margin-top: 100px;

      .selection-process-steps {
        margin-top: 32px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .selection-process-step {
        position: relative;
        width: 352px;
        min-height: 300px;
        border-top: 1px solid $charcoal-ultra-light;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .step-number {
          position: absolute;
          top: 16px;
          font-size: 56px;
          font-weight: 300;
          color: $charcoal-dark;
        }

        .step-title {
          margin-top: 24px;
          margin-left: 46px;
          font-size: 28px;
          font-weight: 400;
          color: $charcoal-dark;
          line-height: 125%;
        }

        .step-details {
          margin-left: 46px;
          margin-top: 8px;
          font-size: 20px;
          font-weight: 400;
          color: $charcoal-dark;
          line-height: 125%;
        }
      }
    }

    .section.awarding-the-prize-section {
      margin-top: 100px;

      .awarding-infos {
        margin-top: 32px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .awarding-info {
          max-width: 555px;
          border-top: 1px solid $charcoal-ultra-light;

          .info-title {
            margin-top: 24px;
            font-size: 28px;
            font-weight: 400;
            color: $charcoal-dark;
            line-height: 125%;
          }

          .info-details {
            margin-top: 8px;
            font-size: 20px;
            font-weight: 400;
            color: $charcoal-dark;
            line-height: 125%;
          }
        }

      }
    }

    .section.experts-section {
      margin-top: 100px;
      margin-bottom: 100px;

      .experts {
        margin-top: 32px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .experts-column {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
        }

        .expert {
          width: 252px;
          border-top: 1px solid $charcoal-ultra-light;
          margin-top: 24px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          .expert-title {
            margin-top: 18px;
            font-size: 16px;
            font-weight: 600;
            color: $charcoal-dark;
            line-height: 135%;
            letter-spacing: -0.01em;
          }

          .expert-bio {
            margin-top: 8px;
            font-size: 16px;
            font-weight: 400;
            color: $charcoal-dark;
            line-height: 135%;
            letter-spacing: -0.01em;
          }

          .expert-linkedin {
            margin-top: 8px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .linkedin-text {
              border-bottom: 1px solid $charcoal-dark;
              font-size: 16px;
              font-weight: 400;
              color: $charcoal-dark;
            }

            img.view-more-arrow {
              margin-left: 4px;
              width: 12px;
            }
          }
        }
      }

      .more-experts-disclaimer {
        margin-top: 64px;
        font-size: 16px;
        font-weight: 400;
        color: $charcoal-light;
        text-align: center;
      }

    }

  }

}

.screen.winning-papers-screen {
  @media screen and (min-width: 0px) and (max-width: 480px) {
    .section.banner-section {
      margin-top: 64px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      img.section-image {
        margin-top: 24px;
        width: 343px;
      }

      .text-content {
        .primary {
          margin-top: 15px;
          font-size: 32px;
          line-height: 120%;
          letter-spacing: -0.01em;
          font-weight: 400;
          color: $charcoal-dark;
        }
        .secondary {
          margin-top: 15px;
          font-size: 20px;
          line-height: 125%;
          letter-spacing: -0.01em;
          font-weight: 400;
          color: $charcoal-dark;
          span.emphasis {
            color: $emphasis-red;
          }
        }
      }

      .annotation-text {
        margin-top: 32px;
        font-size: 24px;
        line-height: 125%;
        font-weight: 400;
        color: $charcoal-dark;
      }

      .button.claim-prize {
        margin-top: 12px;
        width: 320px;
        .button-text {
          font-weight: 500;
        }
      }
    }

    .section.all-papers-section {
      margin-top: 64px;

      .filters-toggles {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 16px;

        .filters-toggle {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          .toggle-text {
            color: $charcoal-dark;
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;
          }

          img.filters-collapse-icon {
            margin-top: -2px;
            margin-left: 6px;
            height: 14px;
            width: 14px;
          }

        }
      }

      .sorts-select {
        .sort-item {
          &:not(:first-child) {
            margin-top: 8px;
          }
        }
        margin-bottom: 24px;
      }

      .filters-select {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .filters-column {
          max-width: 352px;

          .filter-item {
            &:not(:first-child) {
              margin-top: 8px;
            }
          }
        }
      }

      .filters-actions {
        margin-top: 32px;
        margin-bottom: 24px;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .button {
          &:not(:first-child) {
            margin-left: 8px;
          }
        }
      }

      .papers-display {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }

      .paper-container {
        border-top: 1px solid $charcoal-ultra-light;
        padding-top: 36px;
        padding-bottom: 36px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .paper-image {
          align-self: center;
          margin-bottom: 24px;
          border: 1px solid $charcoal-dark;
          img.paper-thumbnail {
            width: 152px;
          }
        }

        .paper-details {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          .paper-title {
            color: $charcoal-dark;
            font-size: 28px;
            line-height: 125%;
            letter-spacing: -0.01em;
            font-weight: 400;
          }

          .paper-authors {
            margin-top: 8px;
            color: $charcoal-dark;
            font-size: 16px;
            line-height: 140%;
            letter-spacing: -0.01em;
            font-weight: 400;
          }

          .influencing-research-title {
            margin-top: 8px;
            font-size: 16px;
            color: $charcoal-dark;
            line-height: 140%;
            letter-spacing: -0.01em;
            font-weight: 600;
          }
        }

      }

    }

  }

  @media screen and (min-width: 480px) {
    .section.banner-section {
      margin-top: 60px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      img.section-image {
        width: 457px;
      }

      .text-content {
        .primary {
          margin-top: 15px;
          max-width: 555px;
          font-size: 56px;
          line-height: 100%;
          letter-spacing: -0.01em;
          font-weight: 400;
          color: $charcoal-dark;
        }
        .secondary {
          margin-top: 15px;
          font-size: 32px;
          line-height: 125%;
          letter-spacing: -0.01em;
          font-weight: 400;
          color: $charcoal-dark;
          span.emphasis {
            color: $emphasis-red;
          }
        }
      }

      .annotation-text {
        margin-top: 32px;
        font-size: 24px;
        line-height: 125%;
        font-weight: 400;
        color: $charcoal-dark;
      }

      .button.claim-prize {
        margin-top: 12px;
        width: 320px;
        .button-text {
          font-weight: 500;
        }
      }
    }

    .section.all-papers-section {
      margin-top: 100px;

      .filters-toggles {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 16px;

        .filters-toggle {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          .toggle-text {
            color: $charcoal-dark;
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;
          }

          img.filters-collapse-icon {
            margin-top: -2px;
            margin-left: 6px;
            height: 14px;
            width: 14px;
          }

        }
      }

      .sorts-select {
        .sort-item {
          &:not(:first-child) {
            margin-top: 8px;
          }
        }
        margin-bottom: 24px;
      }

      .filters-select {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .filters-column {
          max-width: 352px;

          .filter-item {
            &:not(:first-child) {
              margin-top: 8px;
            }
          }
        }
      }

      .filters-actions {
        margin-top: 32px;
        margin-bottom: 24px;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .button {
          &:not(:first-child) {
            margin-left: 8px;
          }
        }
      }

      .papers-display {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }

      .paper-container {
        border-top: 1px solid $charcoal-ultra-light;
        padding-top: 42px;
        padding-bottom: 42px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .paper-image {
          border: 1px solid $charcoal-dark;
          img.paper-thumbnail {
            width: 152px;
          }
        }

        .paper-details {
          width: 952px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          .paper-title {
            color: $charcoal-dark;
            font-size: 28px;
            line-height: 125%;
            letter-spacing: -0.01em;
            font-weight: 400;
          }

          .paper-authors {
            margin-top: 8px;
            color: $charcoal-dark;
            font-size: 16px;
            line-height: 140%;
            letter-spacing: -0.01em;
            font-weight: 400;
          }

          .influencing-research-title {
            margin-top: 8px;
            font-size: 16px;
            color: $charcoal-dark;
            line-height: 140%;
            letter-spacing: -0.01em;
            font-weight: 600;
          }
        }

      }

    }

  }

}

.screen.terms-of-service-screen, .screen.privacy-policy-screen {
  @media screen and (min-width: 0px) and (max-width: 480px) {
    .content {
      margin-bottom: 80px;
      display: flex;
      flex-direction: column;

      .section-title {
        margin-top: 64px;
        text-align: center;
        font-size: 32px;
        line-height: 120%;
        font-weight: 400;
        letter-spacing: -0.01em;
        color: $charcoal-dark;
      }

      .section-subtitle {
        margin-top: 8px;
        margin-bottom: 32px;
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        line-height: 125%;
        letter-spacing: -0.01em;
        color: $charcoal-medium;
      }

      .numbered-section {
        position: relative;
        margin-top: 24px;
        .section-number {
          position: absolute;
          top: 1.5px;
        }
        .section-text {
          margin-left: 24px;
        }
      }

      .header-section {
        .section-header {
          color: $charcoal-dark;
          font-size: 16px;
          line-height: 140%;
          letter-spacing: -0.01em;
          font-weight: 600;
        }
      }

      .list-section {
        position: relative;
        .list-icon {
          position: absolute;
          top: 1.5px;
        }
        .section-text {
          margin-left: 24px;
        }
      }

      .section-text {
        color: $charcoal-dark;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.01em;
        font-weight: 400;

        span.emphasis {
          font-weight: 600;
        }
      }
    }

  }

  @media screen and (min-width: 480px) {
    .content {
      margin-bottom: 100px;
      display: flex;
      flex-direction: column;

      .section-title {
        margin-top: 72px;
        text-align: center;
        font-size: 56px;
        font-weight: 400;
        letter-spacing: 0.01em;
        color: $charcoal-dark;
      }

      .section-subtitle {
        margin-top: 8px;
        margin-bottom: 64px;
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        line-height: 125%;
        letter-spacing: -0.01em;
        color: $charcoal-medium;
      }

      .numbered-section {
        position: relative;
        margin-top: 24px;
        .section-number {
          position: absolute;
          top: 1.5px;
        }
        .section-text {
          margin-left: 24px;
        }
      }

      .header-section {
        .section-header {
          color: $charcoal-dark;
          font-size: 16px;
          line-height: 140%;
          letter-spacing: -0.01em;
          font-weight: 600;
        }
      }

      .list-section {
        position: relative;
        .list-icon {
          position: absolute;
          top: 1.5px;
        }
        .section-text {
          margin-left: 24px;
        }
      }

      .section-text {
        color: $charcoal-dark;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.01em;
        font-weight: 400;

        span.emphasis {
          font-weight: 600;
        }
      }
    }

  }

}

.screen.privacy-policy-screen {

  .section {
    &:not(:first-child) {
      margin-top: 24px;
    }
  }

}
